import styled from "styled-components";
import { Container, media } from "styled-bootstrap-grid";
import { UnderlinedLink } from "styles/global.style";
import { Title } from "components/core/Typography/Title";

const StyledBlogPostsBox = styled.div`
  padding: 32px 0;
`;

const BoxContainer = styled(Container)`
  display: block;
  margin-top: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.sm`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}
`;

const BoxLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const BoxTitle = styled(Title)`
  margin: 0;
`;

const SeeMoreLink = styled(UnderlinedLink)`
  margin-right: 0;
  margin-top: 8px;
  margin-left: 0;
  min-width: 90px;

  svg {
    margin-left: 8px;
    stroke: ${(props) => props.theme.accentColor};
  }

  ${media.md`
    margin-left: 16px;
    margin-top: 0;
    justify-content: flex-end;
  `}
`;

export {
  StyledBlogPostsBox,
  BoxContainer,
  BoxLeftContainer,
  TitleContainer,
  BoxTitle,
  SeeMoreLink,
};
