import { ChatTemplate } from "app-types/template";

export const TRIGGER_TEMPLATES = [
  {
    trigger_template_id: 4,
    emoji: "\ud83d\udc4b",
    background: "#1884ff",
    name: "Welcome first time visitors",
    description:
      "Catch your first time visitors attention by welcoming them the right way",
    chat: {
      name: "Welcome first time visitors",
      audience: { audience: "is_visitor" },
      is_repeatable: false,
      events: [{ delay: 10, type: "widget_load" }],
      conditions: null,
      actions: [
        {
          default_content: {
            content:
              "Are you new here? Just look around and I\u2019ll be here in case you need help.",
          },
          contents: [
            {
              locale: "en",
              content: {
                content:
                  "Are you new here? Just look around and I\u2019ll be here in case you need help.",
              },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 5,
    emoji: "\ud83e\udd73",
    background: "#0b4f9d",
    name: "Repeat visitors",
    description:
      "Engage with visitors that are coming back, they are most likely to convert the second time",
    chat: {
      name: "Repeat visitors",
      audience: { audience: "is_visitor" },
      is_repeatable: false,
      events: [{ delay: 10, type: "widget_load" }],
      conditions: {
        operation: {
          operator: "and",
          type: "operation",
          predicates: [
            {
              operation: "total_views_greater_than",
              target: "stats",
              type: "condition",
              value: 1,
              key: "",
            },
            {
              operation: "session_views_greater_than",
              target: "stats",
              type: "condition",
              value: 0,
              key: "",
            },
          ],
        },
      },
      actions: [
        {
          default_content: {
            content:
              "I\u0027m glad to see you back! Can I help you with anything today?",
          },
          contents: [
            {
              locale: "en",
              content: {
                content:
                  "I\u0027m glad to see you back! Can I help you with anything today?",
              },
            },
          ],
          delay: 30,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 3,
    emoji: "\ud83d\udc4b",
    background: "#18dcff",
    name: "Welcome user",
    description:
      "Say hi to your new users with a welcome message only for those who sign up",
    chat: {
      name: "Welcome user",
      audience: { audience: "is_user" },
      is_repeatable: false,
      events: [{ delay: 10, type: "widget_load" }],
      conditions: null,
      actions: [
        {
          default_content: {
            content:
              "Hi there \ud83d\udc4b \u003Cbr\u003E My name is Luca, your primary point of contact in ACME \u003Cbr\u003E\u003Cbr\u003EI\u0027m here to help you get started with your account \ud83d\ude80 \u003Cbr\u003E\u003Cbr\u003EIt would be a great help to me if you could answer this question: \u003Cbr\u003E\u003Cbr\u003EHow are you planning to use ACME? ",
          },
          contents: [
            {
              locale: "en",
              content: {
                content:
                  "Hi there \ud83d\udc4b \u003Cbr\u003E My name is Luca, your primary point of contact in ACME \u003Cbr\u003E\u003Cbr\u003EI\u0027m here to help you get started with your account \ud83d\ude80 \u003Cbr\u003E\u003Cbr\u003EIt would be a great help to me if you could answer this question: \u003Cbr\u003E\u003Cbr\u003EHow are you planning to use ACME? ",
              },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 1,
    emoji: "\ud83d\udcb0",
    background: "#FF7918",
    name: "Recover pricing page abandons",
    description:
      "Recover people on your pricing page that are about to leave with a catchy message",
    chat: {
      name: "Exit Intent Pricing Page",
      audience: { audience: "is_visitor" },
      is_repeatable: false,
      events: [{ type: "leave_intent" }],
      conditions: {
        operation: {
          operator: "and",
          predicates: [
            {
              operation: "url_contains",
              value: "pricing",
              target: "page",
              type: "condition",
            },
          ],
          type: "operation",
        },
      },
      actions: [
        {
          default_content: {
            content: "Do you have any questions about our plans?",
          },
          contents: [
            {
              locale: "en",
              content: {
                content: "Do you have any questions about our plans?",
              },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 6,
    emoji: "\ud83e\udd28",
    background: "#ffde00",
    name: "Help Center issue",
    description: "Ask to the people staying too long if they need any help",
    chat: {
      name: "Help Center issue",
      audience: { audience: "is_any" },
      is_repeatable: false,
      events: [{ delay: 20, type: "widget_load" }],
      conditions: null,
      actions: [
        {
          default_content: {
            content:
              "Haven\u0027t find the solution to your issue? Chat with us. ",
          },
          contents: [
            {
              locale: "en",
              content: {
                content:
                  "Haven\u2019t find the solution to your issue? Chat with us. ",
              },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 7,
    emoji: "\u26a1\ufe0f",
    background: "#96ff00",
    name: "Product page",
    description: "Engage with people on your product pages",
    chat: {
      name: "Product page",
      audience: { audience: "is_visitor" },
      is_repeatable: false,
      events: [],
      conditions: null,
      actions: [
        {
          default_content: {
            content:
              "Did you know that [Product] can [Product\u2019s benefit]? Wanna know more? just say hi \ud83d\udc4b",
          },
          contents: [
            {
              locale: "en",
              content: {
                content:
                  "Did you know that [Product] can [Product\u2019s benefit]? Wanna know more? just say hi \ud83d\udc4b",
              },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 8,
    emoji: "\ud83d\udd1d",
    background: "#07e300",
    name: "Product page",
    description:
      "Engage with high intent prospect ready to buy but leaving the page",
    chat: {
      name: "Upgrade Plan Page",
      audience: { audience: "is_any" },
      is_repeatable: false,
      events: [{ type: "leave_intent" }],
      conditions: {
        operation: {
          operator: "and",
          predicates: [
            {
              operation: "url_contains",
              value: "upgrade",
              target: "page",
              type: "condition",
            },
          ],
          type: "operation",
        },
      },
      actions: [
        {
          default_content: { content: "What is stopping you from upgrading?" },
          contents: [
            {
              locale: "en",
              content: { content: "What is stopping you from upgrading?" },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
  {
    trigger_template_id: 9,
    emoji: "\ud83d\udc68\ud83c\udffb\u200d\ud83c\udfeb",
    background: "#aaff18",
    name: "Add In-app tutorials",
    description:
      "Add chat messages triggered when a user hover on an element in your web app",
    chat: {
      name: "Add In-app tutorials",
      audience: { audience: "is_user" },
      is_repeatable: false,
      events: [
        {
          action: "hover",
          delay: 0,
          selector: "#customerly-inapp-help-triggers",
          type: "css_selector",
        },
      ],
      conditions: null,
      actions: [
        {
          default_content: {
            content:
              "\u003Cp\u003ECurious about how you can use [PRODUCT]? Check out this video \u003C/p\u003E\u003Cp\u003E\u003Cdiv class=\u0022iframe-wrapper\u0022\u003E\u003Ciframe src=\u0022https://www.youtube.com/embed/_WZ_YaU3aJQ\u0022 frameborder=\u00220\u0022 allowfullscreen=\u0022true\u0022\u003E\u003C/iframe\u003E\u003C/div\u003E\u003C/p\u003E",
          },
          contents: [
            {
              locale: "en",
              content: {
                content:
                  "\u003Cp\u003ECurious about how you can use [PRODUCT]? Check out this video \u003C/p\u003E\u003Cp\u003E\u003Cdiv class=\u0022iframe-wrapper\u0022\u003E\u003Ciframe src=\u0022https://www.youtube.com/embed/_WZ_YaU3aJQ\u0022 frameborder=\u00220\u0022 allowfullscreen=\u0022true\u0022\u003E\u003C/iframe\u003E\u003C/div\u003E\u003C/p\u003E",
              },
            },
          ],
          delay: 20,
          type: "message",
        },
      ],
    },
    author: {
      account_id: 22644,
      public_name: "Customerly",
      username: "customerly",
      bio: "Helping SaaS companies to succeed",
      website: "https://www.customerly.io",
    },
  },
];
