import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import VideoPreview from "components/ui/base/VideoPreview";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FullWidthImage from "components/ui/base/FullWidthImage";
import MobileBox from "components/ui/extended/MobileBox";
import FeatureGrid from "components/ui/base/FeatureGrid";
import VideoLiveChatBox from "components/ui/extended/VideoLiveChatBox";
import InstallLiveChat from "components/ui/extended/InstallLiveChat";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import CannedResponseTemplates from "components/ui/extended/CannedResponseTemplates";
import { CannedResponseTemplate, ChatTemplate } from "app-types/template";
import ReadPost from "components/ui/base/ReadPost";
import BlogPostsBox from "components/ui/base/BlogPostsBox";
import PlainHeaderWithCarousel from "components/ui/base/PlainHeaderWithCarousel";
import ChatTemplates from "components/ui/extended/ChatTemplates";
import { TRIGGER_TEMPLATES } from "constants/templates";

interface LiveChatPageProps {
  data: {
    allCannedResponsesTemplates: {
      nodes: CannedResponseTemplate[];
    };
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: LiveChatPageProps) => {
  const { t } = useTranslation();

  const cannedResponseTemplates = data.allCannedResponsesTemplates.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.live-chat.seo.title")}
        description={t("pages.live-chat.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat-with-superpower.jpg",
          alt: "Live Chat with Superpowers",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.live-chat.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.live-chat.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.live-chat.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.live-chat.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeaderWithCarousel
        title={t("pages.live-chat.title")}
        description={t("pages.live-chat.description")}
        ctaRegister={{ show: true }}
        ctaGetDemo={{ show: true }}
        titleMaxWidth={1180}
        descriptionMaxWidth={700}
        images={[
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-1.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-2.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-3.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-4.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-5.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-6.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-7.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-8.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/live-chat-customization/live-chat-custom-9.jpg",
            alt: "Customerly",
          },
        ]}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Live Chat",
            path: "/live-chat/",
          },
        ]}
      />

      <FullWidthImage
        title={t("pages.live-chat.6.title")}
        description={t("pages.live-chat.6.subtitle")}
        descriptionMaxWidth={700}
        cta={{
          link: "/conversation-inbox/",
          text: t("pages.live-chat.6.cta"),
        }}
        image={{
          relativePath:
            "pages/live-chat/live-chat-dashboard-inbox-backend-for-agents.jpg",
          alt: t("pages.live-chat.6.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat.7.title")}
        description={t("pages.live-chat.7.subtitle")}
        cta={{
          link: "/help-center/",
          text: t("pages.live-chat.7.cta"),
        }}
        image={{
          relativePath:
            "pages/live-chat/live-chat-help-center-autoreply-messages.jpg",
          alt: t("pages.live-chat.7.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <VideoLiveChatBox />

      <VideoPreview
        id={"chat-previews"}
        title={t("pages.live-chat.1.title")}
        paragraph={t("pages.live-chat.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/live-chat/live-chat-typing-previews.mov",
          },
        ]}
      />

      <ReadPost slug={"live-chat-features"} />

      <HorizontalStoriesMenu
        id={"canned-responses"}
        title={t("pages.live-chat.2.title")}
        paragraph={t("pages.live-chat.2.subtitle")}
        storiesPosition={"right"}
        elements={[
          {
            title: t("pages.live-chat.2.elements.1.title"),
            description: t("pages.live-chat.2.elements.1.description"),
            story: {
              relativePath: "pages/live-chat/canned-responses.mp4",
            },
          },
          {
            title: t("pages.live-chat.2.elements.2.title"),
            description: t("pages.live-chat.2.elements.2.description"),
            story: {
              relativePath:
                "pages/live-chat/canned-response-replace-property.mov",
            },
          },
        ]}
      />

      <CannedResponseTemplates
        showMore
        templates={cannedResponseTemplates.slice(0, 2)}
      />

      <ImageWithDescription
        title={t("pages.live-chat.8.title")}
        description={t("pages.live-chat.8.subtitle")}
        cta={{
          link: "/live-chat-api/",
          text: t("pages.live-chat.8.cta"),
        }}
        image={{
          relativePath:
            "pages/live-chat/live-chat-messenger-welcome-message-recognize-users.jpg",
          alt: t("pages.live-chat.8.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"file-sharing"}
        title={t("pages.live-chat.4.title")}
        description={t("pages.live-chat.4.subtitle")}
        image={{
          relativePath:
            "pages/live-chat/live-chat-send-attachments-pdf-png-audio.jpg",
          alt: t("pages.live-chat.4.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ChatTemplates showMore templates={TRIGGER_TEMPLATES.slice(0, 2)} />

      <ImageWithDescription
        title={t("pages.live-chat.11.title")}
        description={t("pages.live-chat.11.subtitle")}
        cta={{
          link: "/smart-assignment-rules/",
          text: t("pages.live-chat.11.cta"),
        }}
        image={{
          relativePath: "pages/live-chat/live-chat-smart-rules.jpg",
          alt: t("pages.live-chat.11.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat.9.title")}
        description={t("pages.live-chat.9.subtitle")}
        image={{
          relativePath:
            "pages/live-chat/live-chat-automated-questions-pre-chat-surveys.jpg",
          alt: t("pages.live-chat.9.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"rich-messages"}
        title={t("pages.live-chat.3.title")}
        description={t("pages.live-chat.3.subtitle")}
        image={{
          relativePath: "pages/live-chat/live-chat-reply-text-richtext.jpg",
          alt: t("pages.live-chat.3.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"chat-archives"}
        title={t("pages.live-chat.5.title")}
        description={t("pages.live-chat.5.subtitle")}
        image={{
          relativePath: "pages/live-chat/live-chat-inboxes.jpg",
          alt: t("pages.live-chat.5.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat.10.title")}
        description={t("pages.live-chat.10.subtitle")}
        image={{
          relativePath: "pages/live-chat/live-chat-notifications-new-chat.jpg",
          alt: t("pages.live-chat.10.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <BlogPostsBox
        category={"live-chat"}
        title={"Live Chat Articles"}
        maxPosts={6}
        postsPerRow={3}
        currentPostSlug={"live-chat"}
      />

      <MobileBox />

      <FeatureGrid
        title={t("pages.live-chat.features.title")}
        sections={[
          {
            title: t("pages.live-chat.features.helpCenter.title"),
            description: t("pages.live-chat.features.helpCenter.description"),
            image: {
              alt: t("pages.live-chat.features.helpCenter.title"),
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t("pages.live-chat.features.helpCenter.links.helpCenter"),
                path: "/help-center/",
              },
              {
                text: t("pages.live-chat.features.helpCenter.links.autoReply"),
                path: "/help-center/#chat-auto-reply",
              },
              {
                text: t(
                  "pages.live-chat.features.helpCenter.links.inChatSearch"
                ),
                path: "/help-center/#inchat-search",
              },
              {
                text: t(
                  "pages.live-chat.features.helpCenter.links.articleEditor"
                ),
                path: "/help-center/#article-editor",
              },
              {
                text: t(
                  "pages.live-chat.features.helpCenter.links.articleStats"
                ),
                path: "/help-center/#article-stats",
              },
              {
                text: t(
                  "pages.live-chat.features.helpCenter.links.googleTagManager"
                ),
                path: "/help-center/#google-tag-manager",
              },
              {
                text: t(
                  "pages.live-chat.features.helpCenter.links.articleFeedback"
                ),
                path: "/help-center/#article-feedback",
              },
            ],
          },
          {
            title: t("pages.live-chat.features.customerEngagement.title"),
            description: t(
              "pages.live-chat.features.customerEngagement.description"
            ),
            image: {
              alt: t("pages.live-chat.features.customerEngagement.title"),
              relativePath: "icons/live-chat-customer-engagement.png",
            },
            links: [
              {
                text: t(
                  "pages.live-chat.features.customerEngagement.links.engagement"
                ),
                path: "/live-chat-engagement/",
              },
              {
                text: t(
                  "pages.live-chat.features.customerEngagement.links.welcomeMessage"
                ),
                path: "/live-chat-engagement/#welcome-message",
              },
              {
                text: t(
                  "pages.live-chat.features.customerEngagement.links.preChatSurveys"
                ),
                path: "/live-chat-engagement/#pre-chat-survey",
              },
              {
                text: t(
                  "pages.live-chat.features.customerEngagement.links.smartAssignmentRules"
                ),
                path: "/smart-assignment-rules/",
              },
              {
                text: t(
                  "pages.live-chat.features.customerEngagement.links.bookMeeting"
                ),
                path: "/live-chat-engagement/#book-meeting",
              },
              {
                text: t(
                  "pages.live-chat.features.customerEngagement.links.helpCenter"
                ),
                path: "/live-chat-engagement/#help-center",
              },
            ],
          },
          {
            title: t("pages.live-chat.features.customization.title"),
            description: t(
              "pages.live-chat.features.customization.description"
            ),
            image: {
              alt: t("pages.live-chat.features.customization.title"),
              relativePath: "icons/live-chat-customizations.png",
            },
            links: [
              {
                text: t(
                  "pages.live-chat.features.customization.links.customization"
                ),
                path: "/live-chat-customization/",
              },
              {
                text: t(
                  "pages.live-chat.features.customization.links.brandMatching"
                ),
                path: "/live-chat-customization/#brand-matching",
              },
              {
                text: t(
                  "pages.live-chat.features.customization.links.chatIcons"
                ),
                path: "/live-chat-customization/#chat-icons",
              },
              {
                text: t(
                  "pages.live-chat.features.customization.links.agentProfiles"
                ),
                path: "/live-chat-customization/#agent-profiles",
              },
              {
                text: t(
                  "pages.live-chat.features.customization.links.companyIntro"
                ),
                path: "/live-chat-customization/#company-intro",
              },
              {
                text: t(
                  "pages.live-chat.features.customization.links.languages"
                ),
                path: "/live-chat-customization/#chat-languages",
              },
            ],
          },
          {
            title: t("pages.live-chat.features.analytics.title"),
            description: t("pages.live-chat.features.analytics.description"),
            image: {
              alt: t("pages.live-chat.features.analytics.title"),
              relativePath: "icons/live-chat-team-reports.png",
            },
            links: [
              {
                text: t("pages.live-chat.features.analytics.links.teamReports"),
                path: "/team-reports/",
              },
              {
                text: t("pages.live-chat.features.analytics.links.chatReports"),
                path: "/team-reports/#chat-reports",
              },
              {
                text: t(
                  "pages.live-chat.features.analytics.links.agentReports"
                ),
                path: "/team-reports/#agent-reports",
              },
              {
                text: t(
                  "pages.live-chat.features.analytics.links.helpCenterReports"
                ),
                path: "/team-reports/#help-center-reports",
              },
              {
                text: t(
                  "pages.live-chat.features.analytics.links.failedSearchesReports"
                ),
                path: "/team-reports/#failed-searches-reports",
              },
            ],
          },
          {
            title: t("pages.live-chat.features.nerds.title"),
            description: t("pages.live-chat.features.nerds.description"),
            image: {
              alt: t("pages.live-chat.features.nerds.title"),
              relativePath: "icons/live-chat-for-developers.png",
            },
            links: [
              {
                text: t("pages.live-chat.features.nerds.links.api"),
                path: "/live-chat-api/",
              },
              {
                text: t("pages.live-chat.features.nerds.links.callbacks"),
                path: "/live-chat-api/#callbacks",
              },
              {
                text: t("pages.live-chat.features.nerds.links.events"),
                path: "/live-chat-api/#event-tracking",
              },
              {
                text: t("pages.live-chat.features.nerds.links.attributes"),
                path: "/live-chat-api/#user-properties",
              },
              {
                text: t(
                  "pages.live-chat.features.nerds.links.userAuthentication"
                ),
                path: "/live-chat-api/#user-authentication",
              },
            ],
          },
          {
            title: t("pages.live-chat.features.mobile.title"),
            description: t("pages.live-chat.features.mobile.description"),
            image: {
              alt: t("pages.live-chat.features.mobile.title"),
              relativePath: "icons/mobile.png",
            },
            links: [
              {
                text: t("pages.live-chat.features.mobile.links.android"),
                path: "/live-chat-for-mobile-apps/",
              },
              {
                text: t("pages.live-chat.features.mobile.links.ios"),
                path: "/live-chat-for-mobile-apps/",
              },
            ],
          },
        ]}
      />

      <InstallLiveChat />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
      <QuestionsAndAnswers
        title={t("pages.live-chat.faq.title")}
        description={t("pages.live-chat.faq.description")}
        elements={[1, 2, 3].map((index) => {
          return {
            question: t(`pages.live-chat.faq.${index}.question`),
            answer: t(`pages.live-chat.faq.${index}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

export const liveChatQuery = graphql`
  query {
    allCannedResponsesTemplates {
      nodes {
        canned_response_template_id
        emoji
        description
        background
        canned_response {
          is_shared
          shortcut
          text
          title
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customerly-live-chat-with-superpower.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
