import React, { FunctionComponent } from "react";
import Heading from "components/core/Typography/Heading";
import { UnderlinedLink } from "styles/global.style";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import {
  EmojiContainer,
  MessageContainer,
  StyledTemplateCard,
  TemplateDetailsContainer,
  TemplatePreviewContainer,
  TextContainer,
} from "./index.style";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";

interface TemplateCardProps {
  id: string;
  path: string;
  title: string;
  description: string;
  background?: string;
  backgroundImageURL?: string;
  text?: string;
  emoji?: string;
}

const TemplateCard: FunctionComponent<TemplateCardProps> = ({
  id,
  path,
  background,
  backgroundImageURL,
  emoji,
  text,
  title,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <StyledTemplateCard key={id}>
      {background && text && (
        <TemplatePreviewContainer
          background={background}
          onClick={() => {
            navigate(path);
          }}
        >
          <MessageContainer>
            <EmojiContainer>{emoji}</EmojiContainer>
            <TextContainer
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </MessageContainer>
        </TemplatePreviewContainer>
      )}
      {backgroundImageURL && (
        <TemplatePreviewContainer
          onClick={() => {
            navigate(path);
          }}
        >
          <img src={backgroundImageURL} alt={title} />
        </TemplatePreviewContainer>
      )}
      <TemplateDetailsContainer>
        <Heading level={3}>{title}</Heading>
        <p>{description}</p>
        <UnderlinedLink as={SafeLink} to={path}>
          {t("components.ui.base.TemplateGrid.openTemplate")}
          <Icon type={"arrow-right"} size={16} />
        </UnderlinedLink>
      </TemplateDetailsContainer>
    </StyledTemplateCard>
  );
};

export default TemplateCard;
