import styled, { css } from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { Col, media } from "styled-bootstrap-grid";
import { MediumComponentContainer } from "styles/global.style";
import Heading from "components/core/Typography/Heading";

export interface StyledTemplatedGridProps {
  centered?: boolean;
  centeredTitle?: boolean;
  alternativeColors?: boolean;
}

export interface ImgContainerProps {
  mediumImage?: boolean;
  bigImage?: boolean;
}

const TemplateGridTitle = styled(Heading)``;

const TemplateGridDescription = styled.p``;

const ShowMoreBackground = styled.div`
  background: ${(props) => props.theme.baseLighten75Color};
  height: 250px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 8px;
  border: 1px solid ${(props) => props.theme.baseLighten75Color};

  svg {
    transition: all 0.3s ease-in-out;
    stroke: ${(props) => props.theme.baseColor};
  }
`;

const TemplateMessage = styled.div`
  max-height: 109px;
  background: ${(props) => props.theme.whiteColor};
  border-radius: 8px;
  height: fit-content;
  font-size: 12px;
  line-height: 18px;
  padding: 16px;
  overflow: hidden;

  p {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }
`;

const TemplateCol = styled(Col)`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  svg {
    margin-left: 2px;
    transition: all 0.3s ease-in-out;
    stroke: ${(props) => props.theme.accentColor};

    width: 16px;
    height: 16px;
  }
`;

const TemplateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Template = styled.li`
  padding: 4px 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 2px;
    transition: all 0.3s ease-in-out;
    stroke: ${(props) => props.theme.accentColor};

    width: 16px;
    height: 16px;

    ${media.md`
      width: 16px;
      height: 16px;
    `}

    ${media.lg`
      width: 18px;
      height: 18px;
    `}
  }
`;

const ImgOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.div<ImgContainerProps>`
  width: 50px;
  height: 50px;

  ${(props) =>
    props.mediumImage &&
    css`
      width: 100px;
      height: auto;
      display: flex;
    `}

  ${(props) =>
    props.bigImage &&
    css`
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const StyledTemplateGrid = styled.section<StyledTemplatedGridProps>`
  ${StyledHeading} {
    font-weight: 600;
    line-height: 1.2;
    color: ${(props) => props.theme.titleColor};
    margin-bottom: 0.3em;
    margin-top: 0.75em;
  }

  ${(props) =>
    props.centeredTitle &&
    css`
      ${TemplateGridTitle}, ${TemplateGridDescription} {
        text-align: center;
      }
    `}

  ${(props) =>
    props.alternativeColors &&
    css`
      ${TemplateGridTitle}, ${TemplateGridDescription} {
        text-align: center;
        margin-top: 0;
      }

      ${MediumComponentContainer} {
        background: ${props.theme.baseLighten95Color};
        padding: 80px 0;

        ${media.md`
          padding: 96px 0;
        `}
      }
    `}

  ${(props) =>
    props.centered &&
    css`
      ${StyledHeading}, ${TemplateGridTitle}, ${TemplateGridDescription}, p {
        text-align: center;
      }

      ${TemplateCol} {
        display: flex;
        align-items: center;
      }

      ${Template} {
        justify-content: center;
      }
    `}
`;

const ButtonCol = styled(Col)`
  margin-top: 48px;
`;

export {
  StyledTemplateGrid,
  TemplateList,
  ShowMoreBackground,
  TemplateMessage,
  Template,
  TemplateCol,
  ButtonCol,
  ShowMoreContainer,
  ImgContainer,
  ImgOuterContainer,
  TemplateGridTitle,
  TemplateGridDescription,
};
