import React, { ReactElement } from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  MediumComponentContainer,
  PageContainer,
  UnderlinedLink,
} from "styles/global.style";
import {
  ShowMoreBackground,
  ShowMoreContainer,
  StyledTemplateGrid,
  TemplateCol,
  TemplateGridDescription,
  TemplateGridTitle,
} from "./index.style";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import Heading from "components/core/Typography/Heading";
import { Link } from "gatsby";

interface TemplateGridProps<T> {
  id?: string;
  title?: string;
  titleLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  description?: string;
  centered?: boolean;
  centeredTitle?: boolean;
  alternativeColors?: boolean;
  showMore?: {
    title: string;
    description: string;
    button: string;
    path: string;
  };
  data: T[];
  component: (data: T) => ReactElement;
}

const TemplateGrid = <T extends unknown>(props: TemplateGridProps<T>) => {
  const titleLevel = props.titleLevel ? props.titleLevel : 2;

  return (
    <StyledTemplateGrid
      id={props.id}
      centered={props.centered}
      centeredTitle={props.centeredTitle}
      alternativeColors={props.alternativeColors}
    >
      <MediumComponentContainer>
        <PageContainer>
          <Container>
            {props.title && (
              <Row>
                <Col>
                  <TemplateGridTitle level={titleLevel}>
                    {props.title}
                  </TemplateGridTitle>
                  {props.description && (
                    <TemplateGridDescription>
                      {props.description}
                    </TemplateGridDescription>
                  )}
                </Col>
              </Row>
            )}
            <Row justifyContent={props.centered ? "center" : "start"}>
              {props.data.map((element, index) => (
                <TemplateCol key={index} lg={4} md={6} sm={12}>
                  {props.component(element)}
                </TemplateCol>
              ))}
              {props.showMore && (
                <TemplateCol key={"showmore"} lg={4} md={6} sm={12}>
                  <Link to={props.showMore.path}>
                    <ShowMoreBackground>
                      <Icon type={"plus"} size={64} />
                    </ShowMoreBackground>
                    <ShowMoreContainer>
                      <Heading level={3}>{props.showMore.title}</Heading>
                      <p>{props.showMore.description}</p>

                      <UnderlinedLink as={SafeLink} to={props.showMore.path}>
                        {props.showMore.button}
                        <Icon type={"arrow-right"} size={16} />
                      </UnderlinedLink>
                    </ShowMoreContainer>
                  </Link>
                </TemplateCol>
              )}
            </Row>
          </Container>
        </PageContainer>
      </MediumComponentContainer>
    </StyledTemplateGrid>
  );
};

export default TemplateGrid;
