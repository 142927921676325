import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ChatTemplate } from "app-types/template";
import { CHAT_TEMPLATES_PATH } from "constants/paths";
import TemplateGrid from "components/ui/base/TemplateGrid";
import TemplateCard from "components/ui/base/TemplateGrid/TemplateCard";
import { getChatTemplatePagePath } from "utils/path";

interface ChatTemplatesProps {
  templates: ChatTemplate[];
  showMore?: boolean;
}

const ChatTemplates: FunctionComponent<ChatTemplatesProps> = ({
  templates,
  showMore,
}) => {
  const { t } = useTranslation();

  return (
    <TemplateGrid
      id={"chat-templates"}
      title={t("components.ui.extended.ChatTemplates.title")}
      description={t("components.ui.extended.ChatTemplates.description", {
        count: templates.length,
      })}
      showMore={
        showMore
          ? {
              title: t("components.ui.extended.ChatTemplates.showMore.title"),
              description: t(
                "components.ui.extended.ChatTemplates.showMore.description"
              ),
              button: t("components.ui.extended.ChatTemplates.showMore.button"),
              path: `${CHAT_TEMPLATES_PATH}/`,
            }
          : undefined
      }
      data={templates}
      component={(chatTemplate) => (
        <TemplateCard
          id={`chat-template-${chatTemplate.trigger_template_id}`}
          path={getChatTemplatePagePath(
            chatTemplate.trigger_template_id,
            chatTemplate.name,
            chatTemplate.author.username
          )}
          background={chatTemplate.background}
          text={chatTemplate.chat.actions[0].contents[0].content.content}
          title={chatTemplate.name}
          description={chatTemplate.description}
          emoji={chatTemplate.emoji}
        />
      )}
    />
  );
};

export default ChatTemplates;
