import React from "react";
import { useTranslation } from "react-i18next";
import { CannedResponseTemplate } from "app-types/template";
import TemplateGrid from "components/ui/base/TemplateGrid";
import { CANNED_RESPONSE_TEMPLATES_PATH } from "constants/paths";
import TemplateCard from "components/ui/base/TemplateGrid/TemplateCard";
import { getCannedResponseTemplatePagePath } from "utils/path";

interface CannedResponseTemplatesProps {
  templates: CannedResponseTemplate[];
  showMore?: boolean;
}

const CannedResponseTemplates = ({
  templates,
  showMore,
}: CannedResponseTemplatesProps) => {
  const { t } = useTranslation();

  return (
    <TemplateGrid
      id={"canned-response-templates"}
      title={t("components.ui.extended.CannedResponseTemplates.title")}
      description={t(
        "components.ui.extended.CannedResponseTemplates.description",
        { count: templates.length }
      )}
      showMore={
        showMore
          ? {
              title: t(
                "components.ui.extended.CannedResponseTemplates.showMore.title"
              ),
              description: t(
                "components.ui.extended.CannedResponseTemplates.showMore.description"
              ),
              button: t(
                "components.ui.extended.CannedResponseTemplates.showMore.button"
              ),
              path: `${CANNED_RESPONSE_TEMPLATES_PATH}/`,
            }
          : undefined
      }
      alternativeColors={true}
      data={templates}
      component={(cannedResponseTemplate) => (
        <TemplateCard
          path={getCannedResponseTemplatePagePath(
            cannedResponseTemplate.canned_response_template_id,
            cannedResponseTemplate.canned_response.title,
            cannedResponseTemplate.author.username
          )}
          id={`canned-response-template-${cannedResponseTemplate.canned_response_template_id}`}
          background={cannedResponseTemplate.background}
          text={cannedResponseTemplate.canned_response.text}
          title={cannedResponseTemplate.canned_response.title}
          description={cannedResponseTemplate.description}
          emoji={cannedResponseTemplate.emoji}
        />
      )}
    />
  );
};

export default CannedResponseTemplates;
