import React from "react";
import { AllPostQueryResult } from "app-types/blog";
import { graphql, useStaticQuery } from "gatsby";
import { Col, Row } from "styled-bootstrap-grid";
import Loop from "components/core/Loop";
import BlogPost from "components/ui/base/BlogPost";
import {
  BoxContainer,
  BoxLeftContainer,
  BoxTitle,
  SeeMoreLink,
  StyledBlogPostsBox,
  TitleContainer,
} from "./index.style";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import { useTranslation } from "react-i18next";
import { getBlogCategoryPath, getBlogTagPath } from "utils/path";
import { BLOG_PATH } from "constants/paths";

export interface BlogPostsBoxProps {
  title: string;
  tag?: string;
  currentPostSlug: string;
  category?: string;
  postsPerRow: number;
  maxPosts: number;
}

const BlogPostsBox = ({
  title,
  tag,
  currentPostSlug,
  category,
  postsPerRow,
  maxPosts,
}: BlogPostsBoxProps) => {
  const { t } = useTranslation();

  const { allPostResult, allWpCategory }: AllPostQueryResult =
    useStaticQuery(graphql`
      query {
        allPostResult: allWpPost(sort: { fields: date, order: DESC }) {
          nodes {
            author {
              node {
                avatar {
                  url
                }
                name
                uri
                description
              }
            }
            tags {
              nodes {
                name
                slug
                uri
              }
            }
            categories {
              nodes {
                name
                slug
                uri
                description
              }
            }
            date(formatString: "MMM DD, YYYY")
            uri
            slug
            title
            content
            seo {
              title
              metaDesc
            }
            smallFeaturedImage: featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      height: 300
                      width: 500
                    )
                  }
                }
              }
            }
          }
        }
        allWpCategory {
          nodes {
            name
            slug
            description
          }
        }
      }
    `);

  const selectedPosts = allPostResult.nodes
    .filter((post) => {
      if (currentPostSlug !== post.slug) {
        if (tag) {
          return post.tags.nodes.map((tag) => tag.slug).includes(tag);
        }
        if (category) {
          return post.categories.nodes
            .map((category) => category.slug)
            .includes(category);
        }
      }
    })
    .slice(0, maxPosts);

  const selectedCategory = allWpCategory.nodes.find(
    (postCategory) => postCategory.slug === category
  );

  const readMoreLink = category
    ? getBlogCategoryPath(category)
    : tag
    ? getBlogTagPath(tag)
    : `${BLOG_PATH}/`;

  return (
    <StyledBlogPostsBox>
      <BoxContainer>
        <Row>
          <Col sm={12}>
            <TitleContainer>
              <BoxLeftContainer>
                <BoxTitle>{title}</BoxTitle>
                {selectedCategory?.description && (
                  <div>{selectedCategory.description}</div>
                )}
              </BoxLeftContainer>

              <SeeMoreLink as={SafeLink} to={readMoreLink}>
                {t("components.ui.base.BlogPostsBox.seeMore")}
                <Icon type={"arrow-right"} size={16} />
              </SeeMoreLink>
            </TitleContainer>
          </Col>
        </Row>
        <Row>
          <Loop items={selectedPosts}>
            {(post, idx) => (
              <Col key={`${idx}-${post.slug}`} sm={12} md={12 / postsPerRow}>
                <BlogPost post={post} showAuthor={false} />
              </Col>
            )}
          </Loop>
        </Row>
      </BoxContainer>
    </StyledBlogPostsBox>
  );
};

export default BlogPostsBox;
