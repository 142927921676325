import styled, { css } from "styled-components";

const MessageContainer = styled.div`
  transition: all 300ms;
  background: ${(props) => props.theme.whiteColor};
  border-radius: 16px;
  padding: 16px;
  position: relative;
  min-height: 96px;
  min-width: 250px;
  user-select: none;

  span {
    color: ${(props) => props.theme.titleColor};
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }

  a {
    font-size: 14px;
    line-height: 16px;
  }
`;

const StyledTemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;

  :hover {
    ${MessageContainer} {
      transform: scale(1.03);
      transition: all 300ms;
    }
  }
`;

const TemplatePreviewContainer = styled.div<{ background?: string }>`
  background: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.baseLighten75Color};

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}

  border-radius: 16px;
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  margin-bottom: 8px;
`;

const EmojiContainer = styled.div`
  position: absolute;
  top: -14px;
  right: 8px;
  font-size: 40px;
`;

const TextContainer = styled.div`
  overflow: hidden;
  max-height: 120px;
  text-overflow: ellipsis;
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.titleColor};
  line-height: 24px;
  height: 70px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  p {
    font-size: 14px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }
`;

const TemplateDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  svg {
    margin-left: 2px;
    transition: all 0.3s ease-in-out;
    stroke: ${(props) => props.theme.accentColor};

    width: 16px;
    height: 16px;
  }
`;

export {
  StyledTemplateCard,
  TemplatePreviewContainer,
  TemplateDetailsContainer,
  MessageContainer,
  EmojiContainer,
  TextContainer,
};
